import { render, staticRenderFns } from "./hm-packaging.vue?vue&type=template&id=5063279a&scoped=true&"
import script from "./hm-packaging.ts?vue&type=script&lang=ts&"
export * from "./hm-packaging.ts?vue&type=script&lang=ts&"
import style0 from "./hm-packaging.scoped.scss?vue&type=style&index=0&id=5063279a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5063279a",
  null
  
)

export default component.exports