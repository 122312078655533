import { hmPackagingService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { PackagingEnum } from '@/resource/enum';
import { HmPackagingEdit } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class AddHmPackaging extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public packaging!: HmPackagingEdit | null;

  public packagingForm: HmPackagingEdit = {
    id: null,
    boxName: '',
    length: 1,
    width: 1,
    height: 1,
    innerLength: 1,
    innerWidth: 1,
    innerHeight: 1,
    netWeight: 1,
    maxSize: 1,
    packType: PackagingEnum.tiled,
    boxNumber: 1,
    sortUnitTime: 0,
    wrapUnitTime: 0,
    price: 0
  };

  public packagingFormRules = {
    boxName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('hmPackaging.inputBoxName')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    boxNumber: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('hmPackaging.inputBoxNumber')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    width: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('hmPackaging.inputWidth')));
            return;
          }

          if(this.packagingForm.innerWidth && Number(value) < this.packagingForm.innerWidth) {
            callback(new Error(translation('hmPackaging.widthCompareError')));
            return;
          }

          callback();
        },
        trigger: 'change'
      }
    ],
    length: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('hmPackaging.inputLength')));
            return;
          }

          if(this.packagingForm.innerLength && Number(value) < this.packagingForm.innerLength) {
            callback(new Error(translation('hmPackaging.lengthCompareError')));
            return;
          }

          callback();
        },
        trigger: 'change'
      }
    ],
    height: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('hmPackaging.inputHeight')));
            return;
          }

          if(this.packagingForm.innerHeight && Number(value) < this.packagingForm.innerHeight) {
            callback(new Error(translation('hmPackaging.heightCompareError')));
            return;
          }

          callback();
        },
        trigger: 'change'
      }
    ],
    innerWidth: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('hmPackaging.inputWidth')));
            return;
          }

          if(this.packagingForm.width && this.packagingForm.width < Number(value)) {
            callback(new Error(translation('hmPackaging.widthCompareError')));
            return;
          }

          callback();
        },
        trigger: 'change'
      }
    ],
    innerLength: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('hmPackaging.inputLength')));
            return;
          }

          if(this.packagingForm.length && this.packagingForm.length < Number(value)) {
            callback(new Error(translation('hmPackaging.lengthCompareError')));
            return;
          }

          callback();
        },
        trigger: 'change'
      }
    ],
    innerHeight: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('hmPackaging.inputHeight')));
            return;
          }

          if(this.packagingForm.height && this.packagingForm.height < Number(value)) {
            callback(new Error(translation('hmPackaging.heightCompareError')));
            return;
          }

          callback();
        },
        trigger: 'change'
      }
    ],
    maxSize: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('hmPackaging.inputMaxSize')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    netWeight: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (Number(value) < 0) {
            callback(new Error(translation('hmPackaging.inputNetWeight')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    packType: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('hmPackaging.inputPackType')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    sortUnitTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (Number(value) < 0) {
            callback(new Error(translation('hmPackaging.inputSortUnitTime')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    wrapUnitTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (Number(value) < 0) {
            callback(new Error(translation('hmPackaging.inputWrapUnitTime')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    price: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (Number(value) < 0) {
            callback(new Error(translation('hmPackaging.inputPrice')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public submitLoading = false;
  private operationType: 'add' | 'edit' = 'add';

  public get packTypeOption(): Array<{ label: string; value: number }> {
    const options = [];
    for (const key in PackagingEnum) {
      if (!isNaN(Number(key)) && typeof Number(key) === 'number') {
        options.push({
          label: translation('classify.' + PackagingEnum[key]),
          value: Number(key)
        });
      }
    }

    return options;
  }

  public dialogOpen(): void {
    if (!this.packaging) {
      this.operationType = 'add';
      this.title = 'hmPackaging.addPackaging';
      return;
    }

    this.operationType = 'edit';
    this.title = 'hmPackaging.editPackaging';
    this.$nextTick(() => {
      Object.assign(this.packagingForm, this.packaging);
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.packagingForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.packagingForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await hmPackagingService.post({ ...this.packagingForm } as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await hmPackagingService.put({ ...this.packagingForm } as any);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.packagingForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
