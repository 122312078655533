import { PackagingEnum } from '@/resource/enum';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { HmPackagingList, HmPackagingListQuery, HmPackagingEdit } from '@/resource/model';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { hmPackagingService } from '@/api';
import { cloneDeep } from 'lodash-es';
import { Message } from 'element-ui';
import AddHmPackaging from './add-hm-packaging/add-hm-packaging.vue';

@Component({
  components: {
    AddHmPackaging
  }
})
export default class HmPackaging extends mixins(PagingMixin, CustomColumnMixin) {
  public $table!: OsTable;

  /**
   * 表单dialog控制
   */
  public dialogVisible = false;

  /**
   * 编辑数据对象
   */
  public editRow: HmPackagingList | null = null;

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<HmPackagingList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true
  };

  /**
   * 查询条件UI配置
   */
  public queryFormOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    }
  ];

  /**
   * 查询表单对象
   */
  public queryForm: Partial<HmPackagingListQuery> = {
    keywords: ''
  };

  /**
   * 表格工具栏配置
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      operationType: 'add',
      slot: 'start',
      label: 'hmPackaging.addPackaging',
      type: 'primary',
      icon: 'el-icon-plus',
      permissionCode: 'hm:packBox:save',
      handleClick: (): void => {
        this.openCreatePackingDialog(null);
      }
    }
  ];

  /**
   * 表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<HmPackagingList>> = [
    {
      prop: 'boxName',
      label: 'hmPackaging.boxName',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'boxNumber',
      label: 'hmPackaging.boxNumber',
      minWidth: '100px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'width',
      label: 'hmPackaging.outerSize',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        const data = rowData as HmPackagingList;

        return `${data.length} x ${data.width} x ${data.height}`;
      }
    },
    {
      prop: 'innerWidth',
      label: 'hmPackaging.innerSize',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        const data = rowData as HmPackagingList;

        return `${data.innerLength} x ${data.innerWidth} x ${data.innerHeight}`;
      }
    },
    {
      prop: 'netWeight',
      label: 'hmPackaging.netWeight',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'maxSize',
      label: 'hmPackaging.maxSize',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'packType',
      label: 'hmPackaging.packType',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        const data = rowData as HmPackagingList;

        return translation(`classify.${PackagingEnum[data.packType]}`);
      }
    },
    {
      prop: 'sortUnitTime',
      label: 'hmPackaging.sortUnitTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'wrapUnitTime',
      label: 'hmPackaging.wrapUnitTime',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'price',
      label: 'hmPackaging.price',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOptions: RowOperation<HmPackagingList> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        size: 'small',
        permissionCode: 'hm:packBox:edit',
        handleClick: (row: HmPackagingList): void => {
          // 编辑
          this.openCreatePackingDialog(row);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        size: 'small',
        permissionCode: 'hm:packBox:delete',
        handleClick: (row: HmPackagingList): void => {
          // 删除
          this.delete(row.id);
        }
      }
    ]
  };

  /**
   * 打开新建窗口
   */
  public openCreatePackingDialog(data: HmPackagingList | null): void {
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.dialogVisible = true;
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public editSuccess(data: HmPackagingEdit): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  /**
   * 删除
   */
  public delete(id: number): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await hmPackagingService.delete(id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.loadData();
  }

  public loadData(): void {
    this.$table.openLoading();

    hmPackagingService
      .getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.$table.closeLoading();
      });
  }

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'hm-packaging');
  }

  public mounted(): void {
    this.$table = this.$refs.hmPackagingTable as OsTable;
    this.loadData();
  }

  /**
   * 分页切换回调
   */
  public pagingData(): void {
    this.loadData();
  }
}
